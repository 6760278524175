import { createSlice } from '@reduxjs/toolkit';
import { ITag } from 'core/types/models/tag/tagType';

const initialState: { tagDetail: ITag | Record<string, never> } = {
  tagDetail: {},
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTagDetail: (state, { payload }) => {
      state.tagDetail = payload.tagDetail;
    },
  },
});

export const { setTagDetail } = tagSlice.actions;

export default tagSlice.reducer;
