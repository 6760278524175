import { createSlice } from '@reduxjs/toolkit';
import { ICategoryChildren, ICategoryList } from 'core/types/models/category/categoryType';

const initialState: { categoryList: ICategoryList; isParentCategorySelected: boolean; categoryDetail: ICategoryChildren | Record<string, never>; categoryParentId: number | null } =
  {
    categoryList: { data: [], meta: {} },
    categoryDetail: {},
    categoryParentId: null,
    isParentCategorySelected: false,
  };

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryList: (state, { payload }) => {
      state.categoryList = payload;
    },
    setChildCategoryDetail: (state, { payload }) => {
      state.categoryDetail = payload.data;
      state.categoryParentId = payload.parentId;
      state.isParentCategorySelected = payload.isParentCategorySelected;
    },
  },
});

export const { setCategoryList, setChildCategoryDetail } = categorySlice.actions;

export default categorySlice.reducer;
