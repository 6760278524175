export const numberWithCommas = (x = '') => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/****************** */
export const countPrice = (items) => {
  return items.reduce((acc, curr) => acc + curr.amount * curr.count, 0);
};

export const cartCountPrice = (items) => {
  return items.reduce((acc, curr) => acc + curr.final_amount, 0);
};

export const countDiscount = (items) => {
  return items.reduce((acc, curr) => acc + (curr.amount - curr.amount_discount), 0);
};
export const countTotal = (items) => {
  return items.reduce((acc, curr) => acc + curr.amount_discount * curr.count, 0);
};
export const countShippingCost = (items) => {
  return items.reduce((acc, curr) => acc + curr.shipping_cost, 0);
};
export function setCookie(cname, cvalue) {
  var d = new Date();
  var exdays = 30;
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + escape(cvalue) + ';' + expires + ';path=/';
}

export function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = unescape(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return unescape(c.substring(name.length, c.length));
    }
  }
  return '';
}
export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export function uuid() {
  const time = new Date().getTime();
  return `${getRandomInt(1000000000, 9999999999)}-${getRandomInt(1000000000, 9999999999)}-${time}-${uuidv4()}`;
}
export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
}
export const toEnNumber = (str = '') => {
  if (str === '') {
    return str;
  }
  return str
    .toString()
    .replace(/[^٠١٢٣٤٥٦٧٨٩۰۱۲۳۴۵۶۷۸۹\d]/g, '') // Replace none numeric values
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632)
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d) => d.charCodeAt(0) - 1776);
};
//convert img to base 64
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.withCredentials = true;
  xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.setRequestHeader('Referrer-Policy', 'no-referrer');

  xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET, POST, OPTIONS');
  xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type, Authorization');
  xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
  xhr.setRequestHeader('Authorization', getCookie('jwt'));
  // Access-Control-Allow-Headers: x-requested-with

  xhr.responseType = 'blob';
  xhr.send();
}
export const blobUrlToFile = (blobUrl) =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        // please change the file.extension with something more meaningful
        // or create a utility function to parse from URL
        const file = new File([blob], 'file.extension', {
          type: blob.type,
        });
        resolve(file);
      });
    });
  });
//replace first 0 with +
export function hideZero(str) {
  return str.replace(/^\d{1}/, function (x) {
    return x.replace(/./g, '+98');
  });
}
// convert hex color to rgb
export const hexToRgbAObject = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 };
  }
};

export const hexToRgbA = (hex, opacity) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return ` rgba(
    ${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}
      ,${opacity})`;
  }
  throw new Error('Bad Hex');
};

export function just_persian(str) {
  const p = /^[\u0600-\u06FF\s]+$/;

  if (!p.test(str)) {
    alert('not format');
  }
}

export const rgbaToHex = (r = '', g = '', b = '', a = '') => {
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  });

  return '#' + outParts.join('');
};

//convert number to rial
export const convertNumberToRial = (number = 0, suffix = ' ریال') => {
  let value = number?.toString()?.replace(/,/g, '');
  // value = parseInt(value);
  let caret = value?.length - 1;
  while (caret - 3 > -1) {
    caret -= 3;
    value = value?.split('');
    value?.splice(caret + 1, 0, ',');
    value = value?.join('');
  }
  return `${value}${suffix}`;
};

// convert persian number to english
export const convertPersianNumberToEnglish = (s = '') => {
  const elem = s
    .toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
  return elem;
};

// extract number from string
export const extractNumberFromString = (s = '') => {
  const elem = s
    .toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
    .replace(/\D/g, '');
  return elem;
};

// convert All object property to en number
export const convertAllPropertyToEnNumber = (object) => {
  const values = {};
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      values[key] = typeof element === 'string' || typeof element === 'number' ? convertPersianNumberToEnglish(element) : element;
    }
  }

  return values;
};

// get price after discount
export const getPriceAfterDiscount = (price, discount) => {
  return extractNumberFromString(price) - (extractNumberFromString(price) * convertPersianNumberToEnglish(discount)) / 100;
};

// seprate string with dash
export const seprateStringWithDash = (text = '') => {
  const reformatText = text.replace(/(\/|-|\\)+/g, '').trim();
  return reformatText.replace(/\s+/g, '-').toLowerCase();
};

// seprate dash with space
export const septateDashWithSpace = (text = '') => {
  return text.replace(/-/g, ' ').toLowerCase();
};

// regex
export const numberRegex = /^[0-9۰-۹]*$/;
export const numberRegexWithFormat = /^[0-9۰-۹,]*$/;
export const mobileNumberRegex = /^(09|۰۹)[0-9۰-۹]{9}$/;

// error response
export const errorResponse = (err) => err.response?.data?.errors;

// date picker
export const convertToDatePicker = (date = '') => {
  const obj = {};
  if (date) {
    date.split('-').forEach((item, index) => {
      switch (index) {
        case 0:
          obj['year'] = Number(item);
          break;
        case 1:
          obj['month'] = Number(item);
          break;
        case 2:
          obj['day'] = Number(item);
          break;
        default:
          break;
      }
    });
  }

  return obj;
};
