import BlogHeader from 'components/Blog/Header/BlogHeader';
import BlogSidebar from 'components/Panel/Sidebar/Blog/BlogSidebar';
import { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

interface IBlogLayoutTwo {
  children: ReactNode;
}

const BlogLayoutTwo: FC<IBlogLayoutTwo> = ({ children }) => {
  const { username }: { username: string } = useParams();

  return (
    <>
      <BlogHeader />
      <div className='p-container panel-withnav'>
        <div className='panel'>
          <BlogSidebar username={username} />
          <div className='blog-layout'>{children}</div>
        </div>
      </div>
    </>
  );
};

export default BlogLayoutTwo;
