import NotFound from 'components/NotFound';
import { blogPanelRoutes, blogPanelRoutesType } from 'core/constants/routeConstants';
import { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { BlogRoute } from 'core/routes/MainRoute';

const IndexRoute = () => {
  const blogPath = Object.keys(blogPanelRoutes).map((key) => blogPanelRoutes[key as blogPanelRoutesType].route);

  return (
    <Switch>
      <Route exact path='/404' component={NotFound} />
      <Route exact path={blogPath} component={BlogRoute} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default memo(IndexRoute);
