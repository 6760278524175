import { useContext, memo, FC } from 'react';
import authContext from 'core/context/Auth/AuthContext';
import { Route, Redirect } from 'react-router-dom';

interface IProtectedBlogPanelRoute {
  isPrivate?: boolean;
  component: any;
}

const ProtectedBlogPanelRoute: FC<IProtectedBlogPanelRoute> = ({ component: Component, isPrivate, ...props }) => {
  const { isLoggedIn } = useContext(authContext);

  if (isLoggedIn || isPrivate) return <Route {...props} render={() => <Component {...props} />} />;
  else return <Route render={() => <Redirect to='/404' />} />;
};

export default memo(ProtectedBlogPanelRoute);
