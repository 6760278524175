import { ConfigProvider } from 'antd';
import fa from 'antd/es/locale/fa_IR';

const AntDesignProvider = ({ children }) => {
  return (
    <ConfigProvider locale={fa} direction='rtl'>
      {children}
    </ConfigProvider>
  );
};

export default AntDesignProvider;
