import { useEffect, useState, useLayoutEffect } from 'react';
import AuthContext from './AuthContext';
import { setCookie, getCookie, uuid } from 'core/utils';
import { instance } from 'core/helpers/api';
import { useMediaQuery } from 'core/hooks/useMediaQuery';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import useIsMounted from 'core/hooks/useIsMounted';

const AuthState = (props) => {
  const dataLogin = parseInt(getCookie('dataLogin'));
  const dataSsn = parseInt(getCookie('dataSsn'));
  const [isLoggedIn, setIsLoggedIn] = useState(dataLogin && dataLogin === 1 ? true : false);
  const [isSsn, setIsSsn] = useState(dataSsn && dataSsn === 1 ? true : false);
  const [userInfo, setUserInfo] = useState({});
  const isMobileWide = useMediaQuery('(max-width: 991px)');
  const isMounted = useIsMounted();

  // hooks
  const history = useHistory();

  useLayoutEffect(() => {
    if (isMobileWide) {
      document.documentElement.style.setProperty('--real100vh', `${window.innerHeight}px`);
    } else {
      document.documentElement.style.setProperty('--real100vh', '100vh');
    }
  });

  const getUserInfo = () => {
    return instance
      .post('/panel/auth/getInfo')
      .then((res) => {
        if (isMounted.current) {
          const data = res.data.data;
          setUserInfo(data);
          if (data.ssn) {
            setCookie('dataLogin', 1);
            setCookie('dataSsn', 1);
            setIsSsn(true);
          } else {
            Cookies.remove('dataSsn');
            setIsSsn(false);
          }
        }
      })
      .catch((res) => {
        if (isMounted.current) {
          if (res?.response?.status === 401) {
            Cookies.remove('dataSsn');
            Cookies.remove('dataLogin');
            setIsSsn(false);
            setIsLoggedIn(false);
            console.log('401');
          }
        }
      });
  };
  const getShopUser = () => {
    return instance.post('/panel/auth/getInfo').then((res) => {
      const data = res.data.data;
      setUserInfo(data);
      if (data.name) {
        setCookie('dataLogin', 1);
        setIsLoggedIn(true);
      }
    });
  };
  const logOut = (username) => {
    console.log('logout');
    setIsLoggedIn(false);
    Cookies.remove('jwt');
    Cookies.remove('dataLogin');
    Cookies.remove('dataSsn');

    history.push(username ? `/${username}` : '/');
  };
  useEffect(() => {
    if (!Cookies.get('uuid')) {
      Cookies.set('uuid', uuid());
    }
  }, []);

  useEffect(() => {
    if (!dataLogin) {
      Cookies.set('dataLogin', 0);
    }
  }, []);
  return (
    <AuthContext.Provider
      value={{
        getUserInfo,
        logOut,
        isLoggedIn,
        setIsLoggedIn,
        userInfo,
        setUserInfo,
        isSsn,
        setIsSsn,
        getShopUser,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
