import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notFoundUrl } from 'core/constants/routeConstants';
import { setProfileDetail } from 'core/redux/reducer/profile/profileSlice';
import { IProfileDetail } from 'core/types/models/profile/profileType';
import { instance } from 'core/helpers/api';

export const useProfileDetailQuery = () => {
  // query
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { username }: { username: string } = useParams();

  return useQueryHook([endPointUrls.PROFILE_GET(username)], () => instance.get(endPointUrls.PROFILE_GET(username)), {
    select: (res: any) => res.data,
    enabled: !!username,
    onSuccess(res) {
      const response = res as IProfileDetail;

      dispatch(setProfileDetail(response));
    },
    onError: async (err: any) => {
      if (err.response.status === 404) {
        push(notFoundUrl);
      }
    },
  }) as UseQueryResult<IProfileDetail, Error>;
};
