import { useEffect, useState } from 'react';
import { BsShopWindow } from 'react-icons/bs';
import style from './Sidebar.module.scss';
import BlogSidebarProfile from 'components/Panel/Sidebar/Blog/Profile/BlogSidebarProfile';
import BlogSidebarList from './List/BlogSidebarList';
import { useSelector } from 'react-redux';
import { RootState } from 'core/redux/store';

const BlogSidebar = ({ username }: { username: string }) => {
  //store
  const { profileDetail } = useSelector((store: RootState) => store.profileStore);

  //state
  const [isChangingStore, setIsChangingStore] = useState(false);

  useEffect(() => {
    if (isChangingStore) {
      setIsChangingStore(false);
    }
  }, [isChangingStore, username]);

  return (
    <aside className={style.container}>
      <div className={style.sticky}>
        <div className={style.cart}>{profileDetail && <BlogSidebarProfile />}</div>

        <div className={style.cart}>
          <BlogSidebarList username={username} />
        </div>

        <a
          href={process.env.REACT_APP_BASE_DOMAIN + ''}
          className='items-center text-[1.16rem] flex p-[16px 24px 16px 16px] bg-white rounded-2xl py-4 pl-4 pr-8 hover:bg-[var(--z-color-light-hover)] shadow-sm cursor-pointer'>
          <BsShopWindow className='ml-6' size={25} />
          <span>برگشت به پنل</span>
        </a>
      </div>
    </aside>
  );
};

export default BlogSidebar;
