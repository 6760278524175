import { withRouter } from 'react-router-dom';

//styles
import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';

import 'assets/style/global.scss';
import { createBrowserHistory } from 'history';
import IndexRoute from 'core/routes/IndexRoute';
import IndexProviders from 'core/providers/IndexProviders';

const browserHistory = createBrowserHistory();
browserHistory.listen((location) => {
  const { hash } = location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }
});

const App = () => {
  return (
    <IndexProviders>
      <IndexRoute />
    </IndexProviders>
  );
};

export default withRouter(App);
