import { IPostImage } from 'core/types/models/post/postType';

export const getDefaultImageDetail = (images: IPostImage[] | []): IPostImage => {
  return images.filter((image) => image.is_default === true)?.map((image) => image)?.[0];
};

export const overrideJoin = (items: any[], overrideAttribute: any) => {
  return items.map((item) => ObjectByString(item, overrideAttribute));
};

export const ObjectByString = (o: any, s: any) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

// remove element array
export const removeArrayElement = (array: any[], element: any) => {
  const index = array.indexOf(element);
  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
};


export const setRootColor = (colors: any) => {
  Object.keys(colors).map((color) => document.documentElement.style.setProperty(`--${color}`, colors[color]));
};