import { useState, useEffect } from 'react';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { HiOutlineFolderMinus, HiOutlineDocumentText } from 'react-icons/hi2';
import { TiTags } from 'react-icons/ti';
import { IoImageOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import style from './List.module.scss';
import { blogPanelRoutes } from 'core/constants/routeConstants';
import { FaRegCommentDots } from 'react-icons/fa';

const ItemList = styled.li`
  cursor: pointer;
  position: relative;
  & {
    a {
      &:after {
        content: '';
        height: 1px;
        background-color: #eeeeee;
        width: calc(100% - 86px);
        position: absolute;
        left: 16px;
        bottom: 0;
      }
    }
  }
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
  .active {
    color: var(--color-primary) !important;
    position: relative;
    &:before {
      color: var(--color-primary) !important;
    }

    .profile__sidebar--border {
      display: block;
      background-color: var(--color-primary);
      top: 16px;
    }
  }
`;

const BlogSidebarList = ({ username }) => {
  const { BLOG_PANEL, CATEGORIES, COMMENTS, GALLERY, TAGS, POSTS } = blogPanelRoutes;
  const location = useLocation();

  const [active, setActive] = useState(BLOG_PANEL.route);

  useEffect(() => {
    if (location.pathname === BLOG_PANEL.dynamicRoute(username)) setActive(BLOG_PANEL.key);
    else setActive(location.pathname);
  }, [location]);

  const list = [
    // {
    //   name: 'پیشخوان',
    //   link: BLOG_PANEL.dynamicRoute(username),
    //   icon: <MdOutlineDashboardCustomize size={25} className='ml-6' />,
    //   key: BLOG_PANEL.key,
    // },
    {
      name: 'نوشته‌ها',
      link: POSTS.dynamicRoute(username),
      icon: <HiOutlineDocumentText size={25} className='ml-6' />,
      key: POSTS.key,
    },
    {
      name: 'دسته‌بندی‌ها',
      link: CATEGORIES.dynamicRoute(username),
      icon: <HiOutlineFolderMinus size={25} className='ml-6' />,
      key: CATEGORIES.key,
    },
    {
      name: 'تگ ها',
      link: TAGS.dynamicRoute(username),
      icon: <TiTags size={25} className='ml-6' />,
      key: TAGS.key,
    },
    {
      name: 'گالری',
      link: GALLERY.dynamicRoute(username),
      icon: <IoImageOutline size={25} className='ml-6' />,
      key: GALLERY.key,
    },
    {
      name: 'دیدگاه‌ها',
      link: COMMENTS.dynamicRoute(username),
      icon: <FaRegCommentDots size={25} className='ml-6' />,
      key: COMMENTS.key,
    },
  ];
  return (
    <ul className={style.list}>
      {list.map((item, index) => {
        return (
          <ItemList key={index}>
            <Link
              target={item.isPremium ? '_blank' : ''}
              className={`${style.itemLink}
              ${active.includes(item.key) ? 'active' : ''}`}
              to={item.link}>
              {item.icon}
              {item.name}
              <span className='profile__sidebar--border'></span>
            </Link>
          </ItemList>
        );
      })}
    </ul>
  );
};

export default BlogSidebarList;
