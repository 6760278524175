import logo from 'assets/images/prof-img.png';
import male from 'assets/images/Gender=Male.png';
import female from 'assets/images/Gender=Female.png';
import style from './HomeHeaderProfile.module.scss';

const HomeHeaderProfile = ({ userInfo, panel, profile, shop, onClick }) => {
  const { name, mobile, gender } = userInfo;

  return (
    <div className={`${style.container} ${panel ? style.panel : ''} ${profile ? style.profile : ''} ${shop ? style.shop : ''}`} onClick={onClick ? onClick : null}>
      <div className={style.cart}>
        <div className={`${style.header} ${panel ? '' : style.hover}`}>
          <div className={style.imgContainer}>
            <img className={style.img} src={gender ? (gender === 1 ? male : gender === 2 ? female : logo) : logo} alt='img' />
          </div>
          <div>
            <div className={style.title}>{name}</div>
            <div className={style.text}>{mobile}</div>
          </div>
        </div>
        <a href={process.env.REACT_APP_BASE_DOMAIN + ''} className={`${style.hover} ${style.logoutLink}`}>
          برگشت به پنل
        </a>
      </div>
    </div>
  );
};

export default HomeHeaderProfile;
