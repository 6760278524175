import { useState } from 'react';
import defaultLogo from 'assets/images/shop_logo_default.png';
import style from './Profile.module.scss';
import { blogLandingRoutes, DOMAIN } from 'core/constants/routeConstants';
import { useSelector } from 'react-redux';
import { RootState } from 'core/redux/store';

const BlogSidebarProfile = () => {
  const { profileDetail } = useSelector((store: RootState) => store.profileStore);

  const [loadingImg, setLoadingImg] = useState(true);

  const { BLOG_HOME } = blogLandingRoutes;

  return (
    <>
      <div className={style.background}>
        <div className={style.backgroundImg} />
      </div>
      <div className={style.profile}>
        <div className={style.imgContainer}>
          <img
            className={style.img}
            src={process.env.REACT_APP_LOGO}
            alt={profileDetail?.data?.title_fa}
            style={{
              display: loadingImg ? 'none' : 'block',
            }}
            onLoad={() => setLoadingImg(false)}
          />
          <img
            className={style.img}
            src={defaultLogo}
            alt={profileDetail?.data?.title_fa}
            style={{
              display: loadingImg ? 'block' : 'none',
            }}
          />
        </div>
        <div className={style.shopName}>{profileDetail?.data?.title_fa}</div>
        <a rel='noreferrer' href={`https://${BLOG_HOME.dynamicRoute(profileDetail?.data?.username + '')}`} target='_blank' className={style.url}>
          {`${DOMAIN}${BLOG_HOME.dynamicRoute(profileDetail?.data?.username + '')}`}
        </a>
        <div className={style.btnContainer}>
          <a rel='noreferrer' href={BLOG_HOME.dynamicRoute(profileDetail?.data?.username + '')} target='_blank' className={style.btn}>
            مشاهده
          </a>

          {/* <Link to={SETTINGS.dynamicRoute(username)} className={style.btn}>
            تنظیمات
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default BlogSidebarProfile;
