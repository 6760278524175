export const endPointUrls = Object.freeze({
  // posts
  POST_CREATE: (shopId?: string) => `/panel/${shopId}/blogs/posts`,
  POST_LIST: (shopId?: string, query?: string) => `/panel/${shopId}/blogs/posts?include=categories,images,tags${query ? `&${query}` : ''}`,
  POST_UPDATE: (shopId: string, postId?: string | number) => `/panel/${shopId}/blogs/posts/${postId}`,
  POST_DELETE: (shopId: string, postId?: string | number) => `/panel/${shopId}/blogs/posts/${postId}`,

  // category
  CATEGORY_LIST: (shopId?: string, query?: string) => `/panel/${shopId}/blogs/categories?include=children${query ? `&${query}` : ''}`,
  CATEGORY_CREATE: (shopId?: string, query?: string) => `/panel/${shopId}/blogs/categories${query ? `?${query}` : ''}`,
  CATEGORY_UPDATE: (shopId?: string, categoryId?: string) => `/panel/${shopId}/blogs/categories/${categoryId}`,
  CATEGORY_DELETE: (shopId?: string, categoryId?: string | number) => `/panel/${shopId}/blogs/categories/${categoryId}`,

  // tag
  TAG_LIST: (shopId?: string, query?: string) => `/panel/${shopId}/blogs/tags${query ? `?${query}` : ''}`,
  TAG_CREATE: (shopId: string, query?: string) => `/panel/${shopId}/blogs/tags${query ? `?${query}` : ''}`,
  TAG_UPDATE: (shopId?: string, tagId?: string) => `/panel/${shopId}/blogs/tags/${tagId}`,
  TAG_DELETE: (shopId?: string, tagId?: string) => `/panel/${shopId}/blogs/tags/${tagId}`,

  // gallery
  BLOG_IMAGE_LIST: (shopId?: string, query?: string) => `/panel/${shopId}/blogs/images${query ? `?${query}` : ''}`,
  BLOG_UPLOAD_IMAGE: (shopId?: string) => `/${shopId}/images/upload`,
  BLOG_IMAGE_UPDATE: (shopId?: string, tagId?: string) => `/panel/${shopId}/blogs/images/${tagId}`,
  BLOG_IMAGE_DELETE: (shopId?: string, tagId?: string) => `/panel/${shopId}/blogs/images/${tagId}`,

  // profile
  PROFILE_GET: (username: string) => `/link/links/username/${username}`,
});
