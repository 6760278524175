import { blogPanelRoutes } from 'core/constants/routeConstants';
import { lazy, memo, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import ProtectedBlogPanelRoute from './ProtectedBlogPanelRoute';
import BlogLayoutTwo from 'layouts/Blog/BlogLayoutTwo';
import BlogLayoutOne from 'layouts/Blog/BlogLayoutOne';
import BlogLayoutThree from 'layouts/Blog/BlogLayoutThree';
import ZpSpinner from '../../components/Spinner/ZpSpinner';

// blog panel
const BlogDashboard = lazy(() => import('pages/blog/dashboard/BlogDashboardPage'));
const BlogPosts = lazy(() => import('pages/blog/post/list/BlogPosts'));
const PostCreate = lazy(() => import('pages/blog/post/create/PostCreatePage'));
const PostUpdate = lazy(() => import('pages/blog/post/update/PostUpdatePage'));
const BlogCategories = lazy(() => import('pages/blog/category/list/BlogCategories'));
const BlogTags = lazy(() => import('pages/blog/tag/list/BlogTags'));
const BlogGallery = lazy(() => import('pages/blog/gallery/list/BlogGallery'));

export const BlogRoute = memo(() => {
  const { BLOG_PANEL, POSTS, POST_CREATE, POST_EDIT, CATEGORIES, TAGS, COMMENTS, GALLERY, SETTINGS } = blogPanelRoutes;

  const routeList = [
    // {
    //   path: BLOG_PANEL.route,
    //   component: () => (
    //     <BlogLayoutTwo>
    //       <BlogDashboard />
    //     </BlogLayoutTwo>
    //   ),
    //   exact: true,
    // },
    {
      path: POSTS.route,
      component: () => (
        <BlogLayoutTwo>
          <BlogPosts />
        </BlogLayoutTwo>
      ),
      exact: true,
    },
    {
      path: POST_CREATE.route,
      component: () => (
        <BlogLayoutThree>
          <PostCreate />
        </BlogLayoutThree>
      ),
      exact: true,
    },
    {
      path: POST_EDIT.route,
      component: () => (
        <BlogLayoutThree>
          <PostUpdate />
        </BlogLayoutThree>
      ),
      exact: true,
    },
    {
      path: CATEGORIES.route,
      component: () => (
        <BlogLayoutTwo>
          <BlogCategories />
        </BlogLayoutTwo>
      ),
      exact: true,
    },
    {
      path: TAGS.route,
      component: () => (
        <BlogLayoutTwo>
          <BlogTags />
        </BlogLayoutTwo>
      ),
      exact: true,
    },
    {
      path: GALLERY.route,
      component: () => (
        <BlogLayoutTwo>
          <BlogGallery />
        </BlogLayoutTwo>
      ),
      exact: true,
    },
  ];

  return (
    <BlogLayoutOne>
      <Suspense fallback={<ZpSpinner spinning fullPage bigGreen />}>
        <Switch>
          {routeList.map((route) => {
            return <ProtectedBlogPanelRoute key={route.path} {...route} />;
          })}
        </Switch>
      </Suspense>
    </BlogLayoutOne>
  );
});
BlogRoute.displayName = 'BlogRoute';
