import MainLayout from 'layouts/MainLayout';
import { useProfileDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useEffect } from 'react';
import { setRootColor } from 'core/utils/utils';

const LayoutProvider = ({ children }) => {
  useProfileDetailQuery();

  useEffect(() => {
    const colors = {
      'color-primary': process.env.REACT_APP_COLOR_PRIMARY,
      'color-primary-hover': process.env.REACT_APP_COLOR_PRIMARY_HOVER,
      'color-primary-light': process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
    };

    setRootColor(colors);
  }, []);

  return <MainLayout>{children}</MainLayout>;
};

export default LayoutProvider;
