import React from 'react';
import { cssTransition, ToastContainer } from 'react-toastify';

const bounce = cssTransition({
  enter: 'animate__animated animate__fadeIn',
  exit: 'animate__animated animate__fadeOut',
});

const ToastProvider = () => {
  return <ToastContainer rtl limit={3} transition={bounce} hideProgressBar={true} />;
};

export default ToastProvider;
