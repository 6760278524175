import { createSlice } from '@reduxjs/toolkit';
import { removeArrayElement } from 'core/utils/utils';

const initialState: { categorySelected: number[]; tagSelected: number[]; editorValue: string; imageSelected: number } = {
  categorySelected: [],
  tagSelected: [],
  editorValue: '',
  imageSelected: 0,
};

export const createPostSlice = createSlice({
  name: 'createPost',
  initialState,
  reducers: {
    setCategorySelected: (state, { payload }) => {
      if (state.categorySelected.includes(payload)) {
        state.categorySelected = [...removeArrayElement(state.categorySelected, payload)];
      } else {
        state.categorySelected = [...state.categorySelected, payload];
      }
    },
    setTagSelected: (state, { payload }) => {
      state.tagSelected = [...state.tagSelected, payload];
    },
    setTagDeSelected: (state, { payload }) => {
      state.tagSelected = [...removeArrayElement(state.tagSelected, payload)];
    },
    setEditorValue: (state, { payload }) => {
      state.editorValue = payload;
    },
    setImageSelected: (state, { payload }) => {
      state.imageSelected = payload;
    },
  },
});

export const { setCategorySelected, setTagDeSelected, setTagSelected, setEditorValue, setImageSelected } = createPostSlice.actions;

export default createPostSlice.reducer;
