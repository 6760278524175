import { useContext, useEffect, memo, ReactNode, FC } from 'react';
import AuthContext from 'core/context/Auth/AuthContext';
import ZpSpinner from 'components/Spinner/ZpSpinner';
import { useProfileDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';

interface IBlogLayoutOne {
  children: ReactNode;
}

const BlogLayoutOne: FC<IBlogLayoutOne> = ({ children }) => {
  const { getUserInfo } = useContext(AuthContext);
  const { isFetching } = useProfileDetailQuery();

  useEffect(() => {
    getUserInfo();
  }, []);

  return <>{isFetching ? <ZpSpinner spinning fullPage bigGreen /> : <>{children}</>}</>;
};

export default memo(BlogLayoutOne);
