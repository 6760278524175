import style from './Spinner.module.scss';
import { Spin } from 'antd';
import { FC, ReactNode } from 'react';

interface IZpSpinner {
  smallBlue?: boolean;
  bigGreen?: boolean;
  smallBlack?: boolean;
  smallGreen?: boolean;
  extraSmallGreen?: boolean;
  children?: ReactNode;
  spinning?: boolean;
  fullPage?: boolean;
  fullSection?: boolean;
  className?: string;
}

const ZpSpinner: FC<IZpSpinner> = ({ smallBlue, bigGreen, smallBlack, smallGreen, extraSmallGreen, children, spinning, fullPage, fullSection, className, ...props }) => {
  const icon = (
    <div
      className={`${style.container} ${
        smallBlue ? style.smallBlue : bigGreen ? style.bigGreen : smallBlack ? style.smallBlack : smallGreen ? style.smallGreen : extraSmallGreen ? style.extraSmallGreen : ''
      }`}
    />
  );

  return (
    <>
      {(fullPage || fullSection) && spinning ? (
        <div className={`${style.background} ${fullPage && 'h-screen'} ${fullSection && 'h-auto'}`}>
          <div
            className={`${style.container} ${
              smallBlue ? style.smallBlue : bigGreen ? style.bigGreen : smallGreen ? style.smallGreen : extraSmallGreen ? style.extraSmallGreen : smallBlack ? style.smallBlack : ''
            }`}
          />
        </div>
      ) : (
        <Spin indicator={icon} className={className} spinning={spinning} {...props}>
          {children}
        </Spin>
      )}
    </>
  );
};
export default ZpSpinner;
