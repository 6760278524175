import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useApiCatcher from 'core/hooks/useApiCatcher';
import { useParams } from 'react-router-dom';

const useQueryHook = (queryKey: QueryKey, queryFn: QueryFunction, queryOptions: UseQueryOptions = {}, showError = true) => {
  // store
  const { username }: { username: string } = useParams();

  const apiCatcher = useApiCatcher();
  return useQuery(queryKey, queryFn, {
    onError: (res: any) => {
      showError && apiCatcher(res?.response.data?.errors);
    },
    enabled: !!username,
    ...queryOptions,
  });
};

export default useQueryHook;
