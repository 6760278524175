import { toast } from 'react-toastify';

const useApiCatcher = () => {
  return (errorResponse:any, isSHowMessage = true) => {
    if (!isSHowMessage) return;
    if (Array.isArray(errorResponse)) {
      return errorResponse?.map((item) =>
        toast.error(item?.message, {
          position: toast.POSITION.TOP_CENTER,
        })
      );
    } else {
      for (const key in errorResponse) {
        if (Object.hasOwnProperty.call(errorResponse, key)) {
          const element = errorResponse[key];
          toast.error(element?.[0] || element, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    }
  };
};

export default useApiCatcher;
