import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
