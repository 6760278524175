import { QueryClientProvider } from '@tanstack/react-query';
import useQueryClientMaster from 'core/service/react-query/uesrQueryClientMaster';

const ReactQueryProviders = ({ children }) => {
  const { queryClientMaster } = useQueryClientMaster();
  return (
    <>
      <QueryClientProvider client={queryClientMaster}>
        {children}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </>
  );
};

export default ReactQueryProviders;
