import { createSlice } from '@reduxjs/toolkit';
import { IBlogGallery } from 'core/types/models/gallery/blogGalleryType';
import { removeArrayElement } from 'core/utils/utils';

const initialState: { categorySelected: number[]; tagSelected: number[]; editorValue: string; imageSelected: IBlogGallery | null } = {
  categorySelected: [],
  tagSelected: [],
  editorValue: '',
  imageSelected: null,
};

export const updatePostSlice = createSlice({
  name: 'updatePost',
  initialState,
  reducers: {
    setUpdatePostCategorySelected: (state, { payload }) => {
      if (state.categorySelected.includes(payload)) {
        state.categorySelected = [...removeArrayElement(state.categorySelected, payload)];
      } else {
        state.categorySelected = [...state.categorySelected, payload];
      }
    },
    setUpdatePostCategoryData: (state, { payload }) => {
      state.categorySelected = [...state.categorySelected, ...payload];
    },
    setUpdatePostTagData: (state, { payload }) => {
      state.tagSelected = [...state.tagSelected, ...payload];
    },
    setUpdatePostTagSelected: (state, { payload }) => {
      state.tagSelected = [...state.tagSelected, payload];
    },
    setUpdatePostTagDeSelected: (state, { payload }) => {
      state.tagSelected = [...removeArrayElement(state.tagSelected, payload)];
    },
    setUpdatePostEditorValue: (state, { payload }) => {
      state.editorValue = payload;
    },
    setUpdatePostImageSelected: (state, { payload }) => {
      state.imageSelected = payload;
    },
    resetUpdatePostPayload: (state) => {
      state.categorySelected = [];
      state.editorValue = '';
      state.imageSelected = null;
      state.tagSelected = [];
    },
  },
});

export const {
  setUpdatePostCategorySelected,
  setUpdatePostCategoryData,
  setUpdatePostTagData,
  setUpdatePostTagDeSelected,
  setUpdatePostTagSelected,
  setUpdatePostEditorValue,
  setUpdatePostImageSelected,
  resetUpdatePostPayload,
} = updatePostSlice.actions;

export default updatePostSlice.reducer;
