import { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import style from './NotFound.module.scss';

const NotFound = () => {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = '#f4f5f7';
  });
  return (
    <div className={style.box}>
      <div className={style.container}>
        <div className={`${style.icon}`}></div>
        <div className={style.title}>متاسفانه صفحه مورد نظر یافت نشد</div>
        <Link className='btn--primary' to='/'>
          دکمه بازگشت
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
