import AntDesignProvider from './antDesign/AntDesignProvider';
import ContextProvider from './context/ContextProvider';
import HelmetProvider from './helmet/HelmetProvider';
import ScrollToTopProvider from './helpers/ScrollToTopProvider';
import LayoutProvider from './layout/LayoutProvider';
import ReactQueryProviders from './react-query/ReactQueryProvider';
import ReduxProvider from './redux/ReduxProvider';
import ToastProvider from './toast/ToastProvider';

const IndexProviders = ({ children }) => {
  return (
    <ReduxProvider>
      <ReactQueryProviders>
        <AntDesignProvider>
          <ScrollToTopProvider>
            <ContextProvider>
                <HelmetProvider>
                <LayoutProvider>{children}</LayoutProvider>

                <ToastProvider />
                </HelmetProvider>
            </ContextProvider>
          </ScrollToTopProvider>
        </AntDesignProvider>
      </ReactQueryProviders>
    </ReduxProvider>
  );
};

export default IndexProviders;
