import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import style from './HeaderBurgerMenu.module.scss';
import BlogSidebarList from 'components/Panel/Sidebar/Blog/List/BlogSidebarList';
import BlogSidebarProfile from 'components/Panel/Sidebar/Blog/Profile/BlogSidebarProfile';
import { BsShopWindow } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { RootState } from 'core/redux/store';

const BlogHeaderBurgerMenu = ({ username }: { username: string }) => {
  //store
  const { profileDetail } = useSelector((store: RootState) => store.profileStore);

  // hooks
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={style.container}>
      <div className={style.cart}>
        <BlogSidebarProfile />
      </div>
      <div className={style.cart}>
        <BlogSidebarList username={username} />
      </div>

      <a
        href={process.env.REACT_APP_BASE_DOMAIN + ''}
        className='items-center w-full text-[1.16rem] flex bg-white rounded-2xl py-4 pl-4 pr-9 hover:bg-[var(--z-color-light-hover)] shadow-sm cursor-pointer'>
        <BsShopWindow className='ml-6' size={22} />
        <span>برگشت به پنل</span>
      </a>
      {/*<button onClick={logOut} className={style.logoutLink}>*/}
      {/*  خروج از حساب*/}
      {/*</button>*/}
    </div>
  );
};

export default BlogHeaderBurgerMenu;
