import { createSlice } from '@reduxjs/toolkit';
import { IBlogGallery } from 'core/types/models/gallery/blogGalleryType';

const initialState: { blogImageDetail: IBlogGallery | Record<string, never> } = {
  blogImageDetail: {},
};

export const blogImageSlice = createSlice({
  name: 'blogImage',
  initialState,
  reducers: {
    setBlogImageDetail: (state, { payload }) => {
      state.blogImageDetail = payload.blogImageDetail;
    },
  },
});

export const { setBlogImageDetail } = blogImageSlice.actions;

export default blogImageSlice.reducer;
