import { useContext, useEffect, useState, useRef } from 'react';
import { FiMenu } from 'react-icons/fi';
import { BiUser } from 'react-icons/bi';
import AuthContext from 'core/context/Auth/AuthContext';
import { Drawer } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import HomeHeaderProfile from 'components/HomeHeaderProfile';
import useOutsideClick from 'core/hooks/useOutsideClick';
import style from './Header.module.scss';
import { blogPanelRoutes } from 'core/constants/routeConstants';
import BlogHeaderBurgerMenu from 'components/Blog/Header/components/BlogHeaderBurgerMenu/HeaderBurgerMenu';
import ZpImage from 'components/UI/Image/ZpImage';

const BlogHeader = () => {
  const { username } = useParams();
  const { POSTS } = blogPanelRoutes;
  const { userInfo } = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const profileRef = useRef(null);

  useOutsideClick(profileRef, () => {
    setShowDropdown(false);
  });
  useEffect(() => {
    setVisible(false);
  }, [location]);

  const onClose = () => {
    setVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <header className={style.main}>
      <div className={style.background}>
        <div className='p-container'>
          <div className={style.row}>
            <div className={style.rightSide}>
              <div className={style.burgerMenu}>
                <FiMenu size={25} className='cursor-pointer' onClick={showDrawer} />
              </div>
              <div className={style.logo}>
                <Link to={POSTS.dynamicRoute(username)} className='flex justify-center'>
                  <ZpImage src={process.env.REACT_APP_LOGO} width={50} />
                </Link>
              </div>
            </div>
            <div className={style.action}>
              {showDropdown && <HomeHeaderProfile userInfo={userInfo} panel />}
              {/* <button className={style.loginBtn} onClick={() => setShowDropdown(true)} ref={profileRef}></button> */}
              <span ref={profileRef}>
                <BiUser size={45} className={style.loginBtn} onClick={() => setShowDropdown(true)} />
              </span>
              <Drawer placement='right' closable={false} onClose={onClose} open={visible} width='296' className='burger-menu' touch-action='none'>
                <BlogHeaderBurgerMenu username={username} />
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default BlogHeader;
