import axios from 'axios';
import { getCookie } from 'core/utils/index';

// if (process.env.NODE_ENV === 'development') {
//   domain = process.env.REACT_APP_ZARIN_BASE_URL;
// } else if (process.env.NODE_ENV === 'production') {
//   domain = window.location.hostname;
// }
// eslint-disable-next-line no-undef
const basUrl = process.env.REACT_APP_ZARIN_BASE_URL;

export const instance = axios.create({
  baseURL: basUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    Authorization: getCookie('jwt'),
    'Access-Control-Allow-Methods': ' GET, POST, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
  },
});
instance.defaults.headers.common['Authorization'] = getCookie('jwt');
instance.interceptors.request.use(
  function (config) {
    const token = getCookie('jwt');
    if (token) config.headers.Authorization = getCookie('jwt');
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
