import { combineReducers } from 'redux';

import blogCategoryStore from './blog/category/categoryReducer';
import blogTagStore from './blog/tag/tagReducer';
import blogImageStore from './blog/image/blogImageReducer';
import createPostStore from './blog/createPost/createPostReducer';
import updatePostStore from './blog/updatePost/updatePostReducer';
import profileStore from './profile/profileSlice';
import modalStore from './modal/modalReducer';

const rootReducer = combineReducers({
  blogCategoryStore,
  blogTagStore,
  modalStore,
  blogImageStore,
  createPostStore,
  updatePostStore,
  profileStore,
});

export default rootReducer;
