import { createSlice } from '@reduxjs/toolkit';
import { IProfileDetail } from 'core/types/models/profile/profileType';

const initialState: {
  profileDetail: IProfileDetail | null;
} = {
  profileDetail: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setProfileDetail: (state, { payload }: { payload: IProfileDetail }) => {
      state.profileDetail = payload;
    },
  },
});

export const { setProfileDetail } = profileSlice.actions;
export default profileSlice.reducer;
