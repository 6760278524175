import { seprateStringWithDash } from 'core/utils';

export const DOMAIN = process.env.REACT_APP_BASE_DOMAIN_NO_HTTP;

export type blogPanelRoutesType = 'BLOG_PANEL' | 'POSTS' | 'POST_CREATE' | 'POST_EDIT' | 'CATEGORIES' | 'TAGS' | 'COMMENTS' | 'GALLERY' | 'SETTINGS';
export const blogPanelRoutes = Object.freeze({
  BLOG_PANEL: {
    key: 'dashboard',
    route: '/panel/blogs/:username',
    dynamicRoute: (username: string) => `/panel/blogs/${username}`,
  },
  POSTS: {
    key: '/post',
    route: '/panel/blogs/:username/posts',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/posts`,
  },
  POST_CREATE: {
    key: '/post',
    route: '/panel/blogs/:username/post/create',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/post/create`,
  },
  POST_EDIT: {
    key: '/post',
    route: '/panel/blogs/:username/post/edit/:postId',
    dynamicRoute: (username: string, postId: string) => `/panel/blogs/${username}/post/edit/${postId}`,
  },
  CATEGORIES: {
    key: 'categories',
    route: '/panel/blogs/:username/categories',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/categories`,
  },
  TAGS: {
    key: 'tags',
    route: '/panel/blogs/:username/tags',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/tags`,
  },
  COMMENTS: {
    key: 'comments',
    route: '/panel/blogs/:username/comments',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/comments`,
  },
  GALLERY: {
    key: 'gallery',
    route: '/panel/blogs/:username/gallery',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/gallery`,
  },
  SETTINGS: {
    key: 'setting',
    route: '/panel/blogs/:username/setting',
    dynamicRoute: (username: string) => `/panel/blogs/${username}/setting`,
  },
});

export const blogLandingRoutes = Object.freeze({
  BLOG_HOME: {
    route: '/:username/blog',
    dynamicRoute: (username: string) => `/${username}/blog`,
  },
  POST_SINGLE: {
    dynamicRoute: (username: string, postId: string | number, postTitle: string) => `/${username}/blog/${postId}/${seprateStringWithDash(postTitle)}}`,
  },
});

export const notFoundUrl = '/404';
