import { Image, ImageProps } from 'antd';
import { FC, SyntheticEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import defaultImage from '../../../assets/images/no-image-light.png';

interface IZpImage extends ImageProps {
  className?: string;
}

const ZpImage: FC<IZpImage> = ({ className, ...props }) => {
  const onErrorHandler = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = defaultImage;
  };
  return <Image preview={false} onError={onErrorHandler} className={twMerge(`w-full ${className}`)} {...props} />;
};

export default ZpImage;
