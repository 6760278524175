import { FC, ReactNode } from 'react';
import BlogHeader from 'components/Blog/Header';

interface IBlogLayoutThree {
  children: ReactNode;
}

const BlogLayoutThree: FC<IBlogLayoutThree> = ({ children }) => {
  return (
    <>
      <BlogHeader />
      <div className='p-container panel-withnav'>
        <div className='panel'>
          <div className='blog-layout'>{children}</div>
        </div>
      </div>
    </>
  );
};

export default BlogLayoutThree;
